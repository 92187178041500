import React, { useState, useEffect } from "react";
import { Modal, Button, Table, Empty, Breadcrumb, Icon, Popconfirm, message, DatePicker } from "antd";
import QueueAnim from "rc-queue-anim";
import { connect } from "react-redux";
import base from "../api/base";
import { store } from "../redux/store";
import { course } from "../redux/reducers";
import locale from "antd/es/date-picker/locale/zh_CN";
import 'moment/locale/zh-cn';
function Timetable(props: any) {
  const [adminMobile, setadminMobile] = useState("");
  const [classid, setclassid] = useState("");
  const [classtoolName, setclasstoolName] = useState("");
  const [teacherToolAccount, setteacherToolAccount] = useState("");
  const [classtoolaccount, setclasstoolaccount] = useState("");
  const [classtype, setclasstype] = useState("");
  const [courseNameTree, setcourseNameTree] = useState([]);
  const [teacher_book_b, setteacher_book_b] = useState([]);
  const [stu_book_b, setstu_book_b] = useState([]);
  const [coteacher_book, setcoteacher_book] = useState([]);
  const [stu_book_audio, setstu_book_audio] = useState([]);
  const [data, setData] = useState(Object);
  const [loading, setLoading] = useState(true);
  const [name, setname] = useState("");
  const [realname, setrealname] = useState("");
  const [schoolMobile, setschoolMobile] = useState("");
  const [schoolName, setschoolName] = useState("");
  const [skype, setskype] = useState("");
  const [starttime, setstarttime] = useState("");
  const [endtime, setendtime] = useState("");
  const [teachername, setteachername] = useState("");
  const [classroomName, setclassroomName] = useState("");
  const [visible, setvisible] = useState(false);
  const [classLiveUrl, setclassLiveUrl] = useState("");
  const [courseOnlineUrl, setCourseOnlineUrl] = useState([]);

  function getData(value: string) {
    const datas = new FormData();
    datas.append("token", sessionStorage.token);
    datas.append("time", value);
    fetch(`${base.hostService}/v1/clockIn/clock/assistant-schedule`, {
      method: "POST",
      body: datas
    })
      .then(res => res.json())
      .then(res => {
        if (res.status === 2000) {
          setData([...res.data.schedule].reverse());
          setLoading(false);
        }
        if (res.status === 2001) {

        }
      });
  }
  function onChange(date: any, dateString: any) {
    if (date) {
      getData(Math.round(date._d.getTime() / 1000).toString());
    } else {
      getData(Math.round(new Date().getTime() / 1000).toString());
    }
  }
  function getCourseInfo(record: any) {
    const datas = new FormData();
    datas.append("token", sessionStorage.token);
    datas.append("grouporderid", record.groupOrderId);
    fetch(`${base.hostApi}/school/course/get-course-info`, {
      method: "POST",
      body: datas
    })
      .then(res => res.json())
      .then(res => {
        if (res.code === 200) {

          setadminMobile(res.data.school.admin ? res.data.school.admin.mobile : '');
          setclassid(res.data.classid);
          setclasstoolName(res.data._classtoolName);
          setteacherToolAccount(res.data._teacherToolAccount);
          setclasstoolaccount(res.data.classtoolaccount);
          setclasstype(res.data.classtype === 1 ? "First Class" : "Regular Class");
          setcourseNameTree(res.data._courseNameTree);
          setname(res.data.name);
          setrealname(res.data.school.admin ? res.data.school.admin.realname : '');
          setschoolMobile(res.data.school.mobile);
          setschoolName(res.data._school_name);
          setskype(res.data.teacher.skype);
          setteachername(res.data.teacher.nickname);
          setstarttime(new Date(res.data.classSchedule.starttime * 1000).toLocaleString());
          setendtime(new Date(res.data.classSchedule.endtime * 1000).toLocaleString());
          setclassroomName(res.data._classroomName);
          setname(res.data.class.name);
          setteacher_book_b(res.data.bookData.teacher_book_b);
          setstu_book_b(res.data.bookData.stu_book_b);
          setcoteacher_book(res.data.bookData.coteacher_book);
          setstu_book_audio(res.data.bookData.stu_book_audio);

          setvisible(true);

          classUrl(record.groupOrderId);
          courseUrl(record.groupOrderId);
        }
      });
  }
  useEffect(() => {
    getData(Math.round(new Date().getTime() / 1000).toString());
  }, []);
  function closeModal() {
    setvisible(false);
  }

  function courseUrl(orderId: string) {
    setCourseOnlineUrl([]);
    const datas = new FormData();
    datas.append("token", sessionStorage.token);
    datas.append("orderId", orderId);
    fetch(`${base.hostService}/v1/classLive/room/get-file-url`, {
      method: "POST",
      body: datas
    })
      .then(res => res.json())
      .then(res => {
        if (res.status === 2000) {
          setCourseOnlineUrl(res.data);
        } else {
        }
      });
  }

  function classUrl(orderId: string) {
    setclassLiveUrl("");
    const datas = new FormData();
    datas.append("token", sessionStorage.token);
    datas.append("userType", "2");
    datas.append("orderId", orderId);
    datas.append("userId", sessionStorage.userid);
    fetch(`${base.hostService}/v1/classLive/room/entry`, {
      method: "POST",
      body: datas
    })
      .then(res => res.json())
      .then(res => {
        if (res.status === 2000) {
          setclassLiveUrl(res.data.uri);
        } else {
        }
      });
  }
  function toFace(record: any) {
    store.dispatch(
      course({
        className: record.className,
        startTime: record.startTime,
        tearcherName: record.tearcherName,
        groupOrderId: record.groupOrderId,
        classId: record.classId
      })
    );
    sessionStorage.setItem(
      "course",
      JSON.stringify({
        className: record.className,
        startTime: record.startTime,
        tearcherName: record.tearcherName,
        groupOrderId: record.groupOrderId,
        classId: record.classId
      })
    );
    window.open(`#/Face/${record.groupOrderId}`, "_blank");
  }
  function toAgentFace() {

    window.open(`#/AgentFace/${sessionStorage.agentId}`, "_blank");
  }
  function cancel(e: any) { }
  const columns: any[] = [
    {
      title: "上课时间",
      dataIndex: "startTime",
      align: "center",
      width: 150,
      render: (text: any) => <div>{new Date(text * 1000).toLocaleString()}</div>
    },
    {
      title: "班级",
      dataIndex: "className",
      align: "center",
      width: 200
    },
    {
      title: "上课教室",
      dataIndex: "classroomName",
      align: "center",
      width: 150
    },
    {
      title: "外教",
      dataIndex: "tearcherName",
      align: "center",
      width: 100
    },
    {
      title: "教材",
      dataIndex: "bookName",
      align: "center",
      width: 200
    },
    {
      title: "签到",
      dataIndex: "groupOrderId",
      align: "center",
      width: 110,
      render: (text: any, record: any) => (
        <Button
          className="sign-btn"
          onClick={() => {
            toFace(record);
          }}
        >
          签到
        </Button>
      )
    },
    {
      title: "上课",
      dataIndex: "classLinks",
      align: "center",
      width: 110,
      render: (text: any, record: any) => (
        <Popconfirm
          title={`请再次确认上课班级为：${record.className}`}
          onConfirm={() => {
            if (classLiveUrl) {
              window.open(classLiveUrl);
            } else {
              message.error("QQ/skype上课方式请点击“更多”");
            }
          }}
          onCancel={cancel}
          okText="Yes"
          cancelText="No"
        >
          <Button
            onClick={() => {
              classUrl(record.groupOrderId);
              courseUrl(record.groupOrderId);
            }}
            className="sign-btn"
          >
            上课
          </Button>{" "}
        </Popconfirm>
      )
    },
    {
      title: "更多",
      dataIndex: "more",
      align: "center",
      width: 110,
      render: (text: any, record: any) => (
        <Button
          className="sign-btn"
          onClick={() => {
            getCourseInfo(record);
          }}
        >
          更多
        </Button>
      )
    }
  ];

  return (
    <div className="timetable-page page bg-size">
      <Modal
        className="courseInfo-box"
        width={1000}
        title={
          <a href={classLiveUrl} target="_blank">
            {classLiveUrl ? "上课链接" : ""}
          </a>
        }
        visible={visible}
        onCancel={closeModal}
        footer={null}
        centered={true}
      >
        <div className="row bg col">
          <div>
            上课时间：{starttime} - {endtime}
          </div>
          <div>
            课件名称：
            <Breadcrumb>
              {courseNameTree.map((item, index) => (
                <Breadcrumb.Item key={index}>{item}</Breadcrumb.Item>
              ))}
            </Breadcrumb>
          </div>
        </div>
        <div className="row">
          <div className="col bg">
            <div>课程编号：{classid}</div>
            <div>课程类项：{classtype}</div>
            <div>课程规模：班课</div>
            <div>上课班级：{name}</div>
            <div>上课教室：{classroomName}</div>
          </div>
          <div className="col col2">
            <div className="col bg">
              <div>所属合作学校：{schoolName}</div>
              <div>合作校联系方式：{schoolMobile}</div>
            </div>
            <div className="col bg">
              <div>云海螺负责人：{realname}</div>
              <div>负责人联系方式：{adminMobile}</div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col bg">
            <div>老师姓名：{teachername}</div>
            <div>老师Skype：{skype}</div>
            <div>
              老师上课工具：{classtoolName}
              {teacherToolAccount ? "：" + teacherToolAccount : ""}
            </div>
            <div>
              学生上课工具：{classtoolName}
              {classtoolaccount ? "：" + classtoolaccount : ""}
            </div>
          </div>

          <div className="col bookData-box">

            {courseOnlineUrl.length > 0 ? (
              courseOnlineUrl.map((item, index) => (
                <div className="item"> 课件NO. {index + 1}
                  <div>
                    <div>
                      <a key={index} href={item} target="_blank">
                        <Icon type="eye" /> 预览
                    </a>
                    </div>
                  </div>
                </div>
              ))
            ) : (
                <div className="col bookData-box">
                  <div className="item">
                    学员课件下载
                <div>
                      {stu_book_b.length > 0 ? (
                        stu_book_b.map((item, index) => (
                          <a key={index} href={item} target="_blank">
                            <Icon type="download" />
                      下载
                          </a>
                        ))
                      ) : (
                          <a>
                            <Icon type="download" />
                      下载
                          </a>
                        )}
                    </div>
                  </div>
                  <div className="item">
                    老师课件下载
              <div>
                      {teacher_book_b.length > 0 ? (
                        teacher_book_b.map((item, index) => (
                          <a key={index} href={item} target="_blank">
                            <Icon type="download" />
                      下载
                          </a>
                        ))
                      ) : (
                          <a>
                            <Icon type="download" />
                    下载
                          </a>
                        )}
                    </div>
                  </div>
                  <div className="item">
                    双师中教教案
              <div>
                      {coteacher_book.length > 0 ? (
                        coteacher_book.map((item, index) => (
                          <a key={index} href={item} target="_blank">
                            <Icon type="download" />
                      下载
                          </a>
                        ))
                      ) : (
                          <a>
                            <Icon type="download" />
                    下载
                          </a>
                        )}
                    </div>
                  </div>
                  <div className="item">
                    音频课件下载
              <div>
                      {stu_book_audio.length > 0 ? (
                        stu_book_audio.map((item, index) => (
                          <a key={index} href={item} target="_blank">
                            <Icon type="download" />
                      下载
                          </a>
                        ))
                      ) : (
                          <a>
                            <Icon type="download" />
                    下载
                          </a>
                        )}
                    </div>
                  </div>
                </div>
              )}
          </div>
        </div>
      </Modal>
      <div className="container">
        <QueueAnim delay={300} className="queue-simple">
          <div key="a" className="title-en">
            <img src="/img/timetable_title_en.png" alt="" />
          </div>
          <div key="b" className="titlebar">
            <img src="/img/timetable_title.png" alt="" />
          </div>
          <div className="bd-box" key="c">
            <div className="line lt" />
            <div className="line rt" />
            <div className="line lb" />
            <div className="line rb" />
            {sessionStorage.token ? (
              <Table
                loading={loading}
                rowKey="groupOrderId"
                bordered
                columns={columns}
                dataSource={data}
                pagination={{ pageSize: 5 }}
              />
            ) : (
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
              )}
          </div>
          <DatePicker key="d" locale={locale} onChange={onChange} />
          <Button key="e" className="campus-btn sign-btn" onClick={toAgentFace}>校区学员签到</Button>
        </QueueAnim>
      </div>
    </div>
  );
}

const mapStateToProps = (state: any) => ({ course: state });
const actionCreators = {
  course
};

export default connect(
  mapStateToProps,
  actionCreators
)(Timetable);

import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Clock from "./Clock";
import MuteBtn from "./MuteBtn";
import base from "../api/base";
import "animate.css";
import FaceCamera from "./FaceCamera";

import {
  showModal,
  voice,
  userName,
  userItem,
  status,
  isGuaranty,
} from "../redux/reducers";
import { store } from "../redux/store";

function Face(props: any) {
  function getData() {
    const data = new FormData();
    data.append("token", sessionStorage.token);
    data.append(
      "groupOrderId",
      sessionStorage.course
        ? JSON.parse(sessionStorage.course).groupOrderId
        : null
    );
    fetch(`${base.hostService}/v1/clockIn/clock/class-student-list`, {
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status === 2000) {
          console.log(res.data.classMember);

          let arr = res.data.classMember;
          let j = arr.length;
          if (arr.length < 5) {
            for (let i = 0; i < 5 - j; i++) {
              arr.push({
                memberId: "",
                fullName: "",
                faceImage: "",
                createdTime: "",
              });
              console.log(arr);
            }
          } else {
            arr.length = 5;
          }
          let arr2 = arr.slice(arr.length - 5);
          console.log(arr);
          store.dispatch(userItem([...arr2]));
        }
      });
  }
  useEffect(() => {
    getData();
  }, []);

  return (
    <div className="face-bg face-page">
      <MuteBtn />
      <div className="container">
        <Clock />
        {store.getState().agentStudentList
          ? store.getState().agentStudentList.map((item: any) => (
              <div className="top" key={item.id}>
                <div>中文名：{item.member ? item.member.fullName : ""}</div>
                <div>英文名：{item.member ? item.member.nickname : ""}</div>
                <div>
                  {store.getState().isGuaranty
                    ? "已完成海螺健康码认证，快来加油学习吧"
                    : "尚未进行海螺健康码认证，请老师注意"}
                </div>
              </div>
            ))
          : ""}
        <div className="main">
          <div className="box left">
            <div className="footer">
              <div className="container">
                <div className="items user-items">
                  {store.getState().userItem
                    ? store
                        .getState()
                        .userItem.map((item: any, index: string) => (
                          <div className="item" key={index}>
                            <Link
                              to={`/addUser/${item.memberId}`}
                              className={`login0 animated  ${
                                item.clockFaceImage
                                  ? "login2 "
                                  : item.faceImageExist === 1
                                  ? "login1 "
                                  : item.faceImageExist === 0
                                  ? "login0 "
                                  : null
                              }`}
                            >
                              <div className="img">
                                {item.faceImage ? (
                                  <img
                                    alt="avatar"
                                    src={
                                      item.faceImage
                                        ? item.faceImage
                                        : item.faceImageExist === 1
                                        ? "/img/avatar1.png"
                                        : item.faceImageExist === 0
                                        ? "/img/avatar0.png"
                                        : null
                                    }
                                  />
                                ) : null}
                              </div>
                            </Link>
                          </div>
                        ))
                    : null}
                </div>
              </div>
            </div>
            <img className="zhayanjing" src="./img/zhayanjing.gif" alt="" />
          </div>
          <div className="box center">
            <FaceCamera />
          </div>
          <div className="box right"></div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state: any) => ({ showModal: state });
const actionCreators = {
  showModal,
  voice,
  userName,
  userItem,
  status,
};

export default connect(mapStateToProps, actionCreators)(Face);

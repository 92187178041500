import React, { Component } from "react";
import { connect } from "react-redux";
import base from "../api/base";
import { store } from "../redux/store";
import AgentStudentList from "./AgentStudentList";
import {
  voice,
  voice2,
  userItem,
  clockNameList,
  isGuaranty,
  anim,
  mute,
  agentStudentList,
} from "../redux/reducers";

class Clock extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isFetch: true,
      msgClassName: null,
      msgTxt: null,
      msgEnTxt: null,
    };

    this.activeAudioRef = React.createRef();
    this.storageEvent = this.storageEvent.bind(this);
    this.addClock = this.addClock.bind(this);
  }

  componentDidMount() {
    window.addEventListener("storage", this.storageEvent);
  }

  componentWillUnmount() {
    window.removeEventListener("storage", this.storageEvent);
  }
  agentStudentList() {
    const datas = new FormData();
    datas.append("token", sessionStorage.token);
    datas.append("agentId", sessionStorage.agentId);
    fetch(`${base.hostService}/v1/clockIn/clock/agent-student-list`, {
      method: "POST",
      body: datas,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status === 2000) {
          let arr = res.data.agentMember;
          let j = arr.length;

          if (arr.length < 5) {
            for (let i = 0; i < 5 - j; i++) {
              arr.push({
                memberId: "",
                fullName: "",
                faceImage: "",
                createdTime: "",
              });
              console.log(arr);
            }
          }
          console.log(arr);
          let arr2 = arr.slice(arr.length - 5);
          store.dispatch(userItem([...arr2]));
        }
      });
  }
  getData() {
    this.setState({
      isFetch: false,
    });
    const data = new FormData();
    const avatar = sessionStorage.avatar ? sessionStorage.avatar : "";
    const avatarStr = avatar.replace("data:image/webp;base64,", "");

    data.append("token", sessionStorage.token);
    data.append("image", avatarStr);

    if (window.location.hash.includes("AgentFace")) {
      /*   data.append('agentId', sessionStorage.agentId); */
    } else {
      data.append(
        "groupOrderId",
        JSON.parse(sessionStorage.course).groupOrderId
      );
    }
    /*  data.append("groupOrderId", JSON.parse(sessionStorage.course).groupOrderId); */
    fetch(`${base.hostService}/v1/clockIn/clock/search`, {
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status === 2000) {
          store.dispatch(agentStudentList([...res.data.faceClock]));
          this.agentStudentList();
          console.log(store.getState().agentStudentList);
          store.dispatch(voice(res.data.voice));
          store.dispatch(anim(true));
          store.dispatch(clockNameList(res.data.faceClock));
          if (res.data.isGuaranty) {
            store.dispatch(isGuaranty(res.data.isGuaranty));
          }
          console.log(store.getState().clockNameList);
          this.addClock();
          this.setState({
            msgClassName: "msg-blue",
            msgTxt: "打卡成功",
            msgEnTxt: "Punch-in succeeded",
          });

          this.clearInfo();

          setTimeout(() => {
            if (this.state.isFetch == false) {
              store.dispatch(agentStudentList([]));
            }
          }, 5000);
        }
        if (res.status === 4000) {
          if (res.data) {
            store.dispatch(voice2(res.data.voice));
          }
          this.setState({
            msgClassName: "msg-red",
            msgTxt: "签到失败，请再试一次",
            msgEnTxt: "Sorry,please try again",
          });
          this.clearInfo();
        }
        if (res.status === 2010) {
          store.dispatch(voice2(res.data.voice));
          this.setState({
            msgClassName: "msg-red",
            msgTxt: "活体检测失败",
            msgEnTxt: "Facial Recognition Failed Due To Image Quality",
          });
          this.clearInfo();
        }

        if (res.status === 2008) {
          this.clearInfo();
        }
      })
      .catch((ex) => {
        console.log(ex);
        console.log(sessionStorage.avatar);
      });
  }

  addClock() {
    const items = store.getState().userItem;
    const itemsJ = store.getState().clockNameList;

    items.forEach((item, i) => {
      itemsJ.forEach((itemJ, j) => {
        if (item.memberId.toString() === itemsJ[j].memberId) {
          items[i].clockFaceImage = itemsJ[j].faceImage;
          store.dispatch(userItem(items));
        }
      });
    });
  }

  storageEvent() {
    const { isFetch } = this.state;

    if (!sessionStorage.avata && isFetch) {
      this.setState({
        msgClassName: "msg-red",
        msgTxt: "请露出全部正脸",
        msgEnTxt: "Face not detected. Please look straight ahead",
      });
    }

    if (sessionStorage.avatar && isFetch) {
      this.setState({
        msgClassName: "msg-red",
        msgTxt: "请保持不动2秒。",
        msgEnTxt: "Please hold still for 2 seconds",
      });
      this.getData();
    }
  }

  audioEnd() {
    if (this.activeAudioRef.current) {
      this.activeAudioRef.current.play();
    }
  }

  clearInfo() {
    setTimeout(() => {
      store.dispatch(voice());
      store.dispatch(clockNameList([]));
      store.dispatch(isGuaranty(""));
      /*   store.dispatch(agentStudentList([])); */
      this.setState({
        isFetch: true,
        msgClassName: null,
        msgTxt: null,
        msgEnTxt: null,
      });
    }, 5000);
  }

  render() {
    const { msgClassName, msgTxt, msgEnTxt } = this.state;
    return (
      <>
        <div className={`msg-txt  ${msgClassName}`}>
          {msgTxt}
          <br />
          {msgEnTxt}
        </div>

        <audio
          className="audio-box activeAudio"
          ref={this.activeAudioRef} /* muted={store.getState().mute} */
        >
          <source type="audio/mpeg" src="../img/active.mp3" />
        </audio>

        {store.getState().voice ? (
          <audio
            className="audio-box audio-end"
            onEnded={this.audioEnd.bind(this)}
            autoPlay
            /*  muted={store.getState().mute} */
          >
            <source
              type="audio/mpeg"
              src={`data:audio/x-mpeg;base64,${store.getState().voice}`}
            />
          </audio>
        ) : null}
        {store.getState().voice2 ? (
          <audio
            className="audio-box"
            autoPlay /* muted={store.getState().mute} */
          >
            <source
              type="audio/mpeg"
              src={`data:audio/x-mpeg;base64,${store.getState().voice2}`}
            />
          </audio>
        ) : null}
      </>
    );
  }
}
const mapStateToProps = (state) => ({ clockNameList: state });
const actionCreators = {
  voice,
  voice2,
  userItem,
  clockNameList,
  anim,
  mute,
};

export default connect(mapStateToProps, actionCreators)(Clock);

import React, { useState, useEffect } from "react";
import { Button, Modal } from "antd";
import { connect } from "react-redux";
import FaceCamera from "./FaceCamera";

import MuteBtn from "./MuteBtn";
import AgentStudentList from "./AgentStudentList";

import base from "../api/base";

import { store } from "../redux/store";
import {
  showModal,
  voice,
  userItem,
  userName,
  screenshot,
  isGuaranty,
} from "../redux/reducers";

function AddUser(props: any) {
  const [visible, setvisible] = useState(false);
  const [face, setface] = useState(false);
  const [modalText, setmodalText]: any = useState(null);
  const [ajax, setajax] = useState(null);
  const [addUserBtn, setaddUserBtn] = useState(false);
  const [screenshotBtn, setscreenshotBtn] = useState(true);
  const [fullName, setfullName] = useState(null);
  const [nickname, setnickname] = useState(null);
  const [mobile, setmobile] = useState(null);
  const [age, setage] = useState(null);
  function getStudentInfo() {
    const data = new FormData();
    data.append("token", sessionStorage.token);
    data.append("memberId", props.match.params.id);
    // 获取学员人脸图片信息
    fetch(`${base.hostService}/v1/clockIn/collect/get-member-face`, {
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status === 2000) {
          setfullName(res.data.member.fullName);
          setnickname(res.data.member.nickname);
          setmobile(res.data.member.mobile);
          setage(res.data.member.memberDetail.age);
        }
        if (res.status === 2001) {
          props.history.push("/Login");
        }
      });
  }
  useEffect(() => {
    /*  if (!sessionStorage.token) {
      props.history.push('/Login');
    } */
    const token = sessionStorage.token
      ? sessionStorage.token
      : window.location.hash.replace(`#/AddUser/${props.match.params.id}/`, "");

    sessionStorage.setItem("token", token);
    getStudentInfo();

    window.addEventListener("storage", (e: any) => {
      setface(e.storageArea.face);
    });
  }, []);

  function screenshotClick() {
    store.dispatch(screenshot(sessionStorage.faceImg));
    setaddUserBtn(true);
    setscreenshotBtn(false);
  }
  function change() {
    store.dispatch(screenshot(null));
    setaddUserBtn(false);
    setscreenshotBtn(true);
  }

  const handleOk = () => {
    setvisible(false);
    setaddUserBtn(false);
    setscreenshotBtn(true);

    store.dispatch(screenshot(null));

    if (ajax === 2000) {
      window.history.go(-1);
    }
  };

  function getData() {
    setvisible(true);
    setmodalText("In progress...");

    const data = new FormData();
    data.append("token", sessionStorage.token);
    data.append("memberId", props.match.params.id);
    data.append(
      "image",
      store.getState().screenshot.replace("data:image/webp;base64,", "")
    );
    // 注册
    fetch(`${base.hostService}/v1/clockIn/collect/add-user`, {
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status === 2000) {
          setvisible(true);
          setmodalText("Face Successfully Added");
          setajax(res.status);
        } else if (res.status === 4000) {
          setvisible(true);
          setajax(res.status);
          setmodalText(res.message);
        } else {
          setmodalText("Sorry, please try again");
        }
      });
  }

  return (
    <div className="face-bg add-user-page">
      <MuteBtn />
      <AgentStudentList />
      <div className="container">
        {face === true ? (
          <div className="msg-txt msg-red">
            请保持不动2秒。
            <br />
            Please hold still for 2 seconds
          </div>
        ) : (
          <div className="msg-txt msg-red">
            请露出全部正脸。
            <br />
            Face not detected. Please look straight ahead
          </div>
        )}

        <Modal
          className="add-user-modal"
          mask={false}
          title=""
          visible={visible}
          width={703}
          closable={false}
          centered
          footer={
            ajax === 2000 || ajax === 4000
              ? [
                  <Button key="submit" type="primary" onClick={handleOk}>
                    Confirm
                  </Button>,
                ]
              : null
          }
        >
          <p>{modalText}</p>
        </Modal>

        <div className="main">
          <div className="box left">
            <div className="footer">
              <div className="items user-items">
                {store.getState().userItem
                  ? store
                      .getState()
                      .userItem.map((item: any, index: string) => (
                        <div
                          className={`item ${
                            store.getState().isGuaranty ? "green" : ""
                          }`}
                          key={index}
                        >
                          <div className="img">
                            {item.faceImage ? (
                              <img
                                alt="avatar"
                                src={item.faceImage ? item.faceImage : null}
                              />
                            ) : null}
                          </div>
                        </div>
                      ))
                  : null}
              </div>
            </div>
            <img className="zhayanjing" src="./img/zhayanjing.gif" alt="" />
          </div>
          <div className="box center">
            {/*  <div className="animate-box">
              <div className="line" />
              <div className="leida l">
                <div>
                  <div>
                    <div />
                  </div>
                </div>
              </div>
              <div className="leida r">
                <div>
                  <div>
                    <div />
                  </div>
                </div>
              </div>
            </div> */}
            <FaceCamera />
          </div>
          <div className="box right">
            {/*  <div className="add-user-info">
              <div className="ico">
                <img src="/img/add_user_info.png" alt="" />
              </div>
              <p>
                1、请保持摄像区域仅出现一位小朋友
                <br />
                2、确认光线充足，可以清晰看见脸部特征
                <br />
                3、请将人脸靠近摄像头，脸部尽量充满摄像区域并居中
              </p>
            </div> */}
          </div>
        </div>
        <div className="footer add-user-btn">
          <div className="container">
            <div className="btn-box">
              {addUserBtn ? (
                <button
                  className="btn change-btn"
                  type="button"
                  onClick={change}
                >
                  Discard
                </button>
              ) : null}
              {screenshotBtn ? (
                <button
                  className="btn screenshot-btn"
                  type="button"
                  onClick={screenshotClick}
                >
                  <img src="/img/screenshot_ico.png" alt="" />
                  Take Snapshot
                </button>
              ) : null}
              {addUserBtn ? (
                <button
                  className="btn confirm-btn"
                  onClick={getData}
                  type="button"
                >
                  Confirm
                </button>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state: any) => ({ showModal: state });
const actionCreators = {
  showModal,
  voice,
  userItem,
  userName,
  screenshot,
};

export default connect(mapStateToProps, actionCreators)(AddUser);

import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Clock from "./Clock";
import ClockNameList from "./ClockNameList";
import CourseInfo from "./CourseInfo";
import MuteBtn from "./MuteBtn";
import AgentStudentList from "./AgentStudentList";
import base from "../api/base";
import "animate.css";
import FaceCamera from "./FaceCamera";
import moment from "moment";
import { showModal, voice, userName, userItem, status } from "../redux/reducers";
import { store } from "../redux/store";

function Face(props: any) {
  return (
    <div className="face-bg face-page">
      <MuteBtn />
      <div className="container">
        <Clock />
        <div>
          <AgentStudentList />

          <div className={store.getState().agentStudentList ? "content" : ""}>
            {store.getState().agentStudentList
              ? store.getState().agentStudentList.map((item: any) => (
                  <div className="top" key={item.id}>
                    <div>中文名：{item.member ? item.member.fullName : ""}</div>
                    <div>英文名：{item.member ? item.member.nickname : ""}</div>
                  </div>
                ))
              : ""}
          </div>
        </div>
        <div className="main">
          <div className="box left">
            <div className="footer">
              <div className="items user-items">
                {store.getState().userItem
                  ? store.getState().userItem.map((item: any, index: string) => (
                      <div className="item" key={index}>
                        <Link
                          to={`/addUser/${item.createdTime}`}
                          className={`login0 animated  ${item.faceImage ? "login2 heartBeat" : null}`}
                        >
                          <div className="txt">{item.fullName}</div>
                          <div className="img">
                            {item.faceImage ? <img alt="avatar" src={item.faceImage ? item.faceImage : null} /> : null}
                          </div>
                        </Link>
                      </div>
                    ))
                  : null}
              </div>
            </div>

            <img className="zhayanjing" src="./img/zhayanjing.gif" alt="" />
          </div>
          <div className="box right"></div>
          <div className="box center">
            <FaceCamera />
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state: any) => ({ showModal: state });
const actionCreators = {
  showModal,
  voice,
  userName,
  userItem,
  status
};

export default connect(mapStateToProps, actionCreators)(Face);

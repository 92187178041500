import React from 'react';
import {
  Form, Icon, Input, Button, message,
} from 'antd';
import md5 from 'md5';
import QueueAnim from 'rc-queue-anim';
import { connect } from 'react-redux';
import base from '../api/base';
import { store } from '../redux/store';
import {
  showModal,
  voice,
  voice2,
  userItem,
  userName,
  status,
  token,
} from '../redux/reducers';

function NormalLoginForm(props:any) {
  const handleSubmit = (e:any) => {
    e.preventDefault();
    props.form.validateFields((err:any, values:any) => {
      if (!err) {
        const data = new FormData();
        data.append('username', values.username);
        data.append('password', md5(values.password));

        fetch(`${base.hostApi}/school/user/login`, {
          method: 'POST',
          body: data,
        })
          .then(res => res.json())
          .then((res) => {
            if (res.code === 200) {
              message.success('登录成功');
              store.dispatch(token(res.data.token));
           
              sessionStorage.setItem('token', res.data.token);
              sessionStorage.setItem('userid', res.data.userid);
              sessionStorage.setItem('agentId', res.data.agent_id);
              setTimeout(() => {
                props.history.push('/Timetable');
              }, 200);
            } else {
              message.warning(res.msg);
            }
          });
      }
    });
  };

  const { getFieldDecorator } = props.form;
  return (
    <div className="login-page page bg-size">
      <QueueAnim delay={300} className="queue-simple">
        <div className="container" key="a">
          <div className="title-en">
            <img src="/img/login_title_en.png" alt="" />
          </div>

          <div className="box">
            <div className="titlebar">
              <img src="/img/login_form_title.png" alt="" />
            </div>
            <Form onSubmit={handleSubmit} className="login-form">
              <Form.Item>
                {getFieldDecorator('username', {
                  rules: [
                    { required: true, message: 'Please input your username!' },
                  ],
                })(
                  <Input
                    prefix={
                      <Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />
                    }
                    placeholder="Username"
                  />,
                )}
              </Form.Item>
              <Form.Item>
                {getFieldDecorator('password', {
                  rules: [
                    { required: true, message: 'Please input your Password!' },
                  ],
                })(
                  <Input
                    prefix={
                      <Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />
                    }
                    type="password"
                    placeholder="Password"
                  />,
                )}
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="login-form-button"
                >
                  LOG IN
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </QueueAnim>
    </div>
  );
}

const mapStateToProps = (state:any) => ({ showModal: state });
const actionCreators = {
  showModal,
  voice,
  voice2,
  userItem,
  userName,
  status,
  token,
};

const Login = Form.create({ name: 'normal_login' })(connect(
  mapStateToProps,
  actionCreators,
)(NormalLoginForm));
export default Login;

import React, { useState, useEffect, useRef } from "react";

import { connect } from "react-redux";
import { store } from "../redux/store";
import { screenshot, anim, mute } from "../redux/reducers";

function FaceCamera() {
  const [arcMin, setarcMin] = useState(false);
  const [arcMax, setarcMax] = useState(false);
  const [bdMax, setbdMax] = useState(false);
  const audioBgRef: any = useRef(null);
  const bdRef: any = useRef(null);
  const audioMaxRef: any = useRef(null);

  useEffect(() => {
    audioBgRef.current.volume = 0.2;
    bdRef.current.addEventListener("animationend", () => {
      store.dispatch(anim(false));
    });

    const play = setTimeout(() => {
      if (audioMaxRef.current) {
        /*   audioMaxRef.current.play(); */
      }

      setarcMin(true);
      setarcMax(true);
      setbdMax(true);
    }, 500);
    return function clear() {
      clearTimeout(play);
    };
  }, []);
  return (
    <div className="face-camera-box animated">
      <div className="audio-box">
        <audio className="audio-bg" ref={audioBgRef} loop={true} autoPlay={true} muted={store.getState().mute}>
          <source type="audio/mpeg" src="./img/bg.mp3" />
        </audio>
        <audio className="audio-max" ref={audioMaxRef} muted={store.getState().mute}>
          <source type="audio/mpeg" src="./img/max.mp3" />
        </audio>
      </div>
      {/*   <div className="arc-box">
        <div className={`arc arc1 ${arcMin ? "arc-min" : null}`} />
        <div className={`arc arc2 ${arcMin ? "arc-min" : null}`} />
        <div className="arc arc3" />
        <div className={`arc arc4 ${arcMax ? "arc-max" : null}`} />
        <div className="arc arc5" />
      </div> */}
      <div className="arc-box">
        <div className="waiquan">
         
          <div className="zhengyanjing">
            <div className="tongkong"></div>
          </div>
        </div>
      </div>
      {/*  <div
        ref={bdRef}
        className={`bd bd1  animated ${bdMax ? "bd-max" : null} ${
          store.getState().anim ? "tada" : null
        }`}
      /> */}
      <div
        ref={bdRef}
        className={
          "bd bd2 bdMax"
        } /* {`bd bd2  animated ${bdMax ? "bd-max" : null} ${
          store.getState().anim ? "tada" : null
        }`} */
      >
        <img src={store.getState().screenshot} alt="" className="canvas-face" />

        <div className="ifr-box">
          <iframe title="faceiframe" className="face-iframe" src="./face.html" frameBorder="0" />
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state: any) => ({ screenshot: state });
const actionCreators = {
  screenshot,
  anim,
  mute
};

export default connect(mapStateToProps, actionCreators)(FaceCamera);

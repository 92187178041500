import React from "react";

import { HashRouter as Router, Route, Redirect } from "react-router-dom";
import "antd/dist/antd.css";
import "./css/main.scss";

import Home from "./components/Home";
import Face from "./components/Face";
import Login from "./components/Login";
import Timetable from "./components/Timetable";
import AddUser from "./components/AddUser";
import AgentFace from "./components/AgentFace";

function App() {
  return (
    <Router>
      <>
        <Route path="/" exact component={Login} />
        {/*  <Route path="/Face/:id" component={Face} />
           <Route path="/Timetable" component={Timetable} />*/}
        <Route path="/AddUser/:id" component={AddUser} /> 
        <Route path="/Login" component={Login} />
        <Route path="/Home" component={Home} />
        <Route
          path="/Timetable"
          render={() =>
            !sessionStorage.token ? <Redirect to="/Login" /> : <Timetable />
          }
        />
        <Route
          path="/Face/:id"
          render={() =>
            !sessionStorage.token ? <Redirect to="/Login" /> : <Face />
          }
        />
        <Route
          path="/AgentFace/:id"
          render={() =>
            !sessionStorage.token ? <Redirect to="/Login" /> : <AgentFace />
          }
        />
        {/* <Route
          path="/AddUser/:id"
          render={() =>
            !sessionStorage.token ? <Redirect to="/Login" /> : <AddUser />
          }
        />  */}
      </>
    </Router>
  );
}

export default App;

import React, { useState, useEffect } from "react";
import base from "../api/base";
import { store } from "../redux/store";
import { agentStudentList, userItem } from "../redux/reducers";

function AgentStudentList() {
  const [agentMember, setagentMember] = useState([
    {
      memberId: "",
      fullName: "",
      faceImage: "",
      createdTime: ""
    }
  ]);

  function getData() {
    const datas = new FormData();
    datas.append("token", sessionStorage.token);
    datas.append("agentId", sessionStorage.agentId);
    fetch(`${base.hostService}/v1/clockIn/clock/agent-student-list`, {
      method: "POST",
      body: datas
    })
      .then(res => res.json())
      .then(res => {
        if (res.status === 2000) {
          /*  setagentMember(res.data.agentMember); */
          let arr = res.data.agentMember;
          let j=arr.length
          if (arr.length < 5) {
          for (let i = 0; i < (5-j); i++) {
              arr.push({
                memberId: "",
                fullName: "",
                faceImage: "",
                createdTime: ""
              });
              console.log(arr);
            }
          }
         let arr2= arr.slice(arr.length-5)
          store.dispatch(userItem([...arr2]));
          console.log(arr);
          console.log(arr2);
        }
        if (res.status === 2001) {
        }
      });
  }

  useEffect(() => {
    getData();
  }, []);

  return (
  <div></div> 
  );
}

export default AgentStudentList;

import React from "react";
import { fn } from "moment";
interface LabelledValue {
  /*  props: any; */
  fullName: any;
  nickname: any;
  mobile: any;
  age: any;
}
enum Direction {
  Up = 1,
  Down = 3,
  Left,
  Right
}

function Home(props: LabelledValue) {
  function fn() {
    
    console.log(document.activeElement);
  }
  
  const { fullName, nickname, mobile, age } = props;
  return (
    <div className="content">
      {Direction.Left}

      <iframe onFocus={fn} src="/face.html" />
      <div className="title">
        <span>/ </span>
        Student Information
        <span> /</span>
      </div>
      <div>
        fullName：
        {fullName}
      </div>
      <div>
        nickname：
        {nickname}
      </div>
      <div>
        mobile：
        {mobile}
      </div>
      <div>
        age：
        {age}
      </div>
    </div>
  );
}

export default Home;

import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { store } from "../redux/store";
import { mute } from "../redux/reducers";

function MuteBtn(props: any) {
  const [full, setfull] = useState(false);

  function clickMute() {
    store.dispatch(mute(!store.getState().mute));
  }
  function fullScreen() {
    if (document.fullscreen == true) {
      document.exitFullscreen();
      setfull(false);
    }
    if (document.fullscreen == false) {
      document.body.requestFullscreen();
      setfull(true);
    }
  }

  return (
    <div>
      <div className="full" onClick={fullScreen}>
        <img src={`/img/full${full ? "false" : "true"}.png`} alt="" />
      </div>
      <div className="sound" onClick={clickMute}>
        <img src={`/img/sound${store.getState().mute ? "false" : "true"}.png`} alt="" />
      </div>
    </div>
  );
}
const mapStateToProps = (state: any) => ({ mute: state });
const actionCreators = {
  mute
};

export default connect(mapStateToProps, actionCreators)(MuteBtn);

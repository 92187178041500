export function reducer(state = {}, action) {
  switch (action.type) {
    case "showModal":
      return Object.assign({}, state, {
        showModal: action.data.value
      });

    case "voice":
      return Object.assign({}, state, {
        voice: action.data.value
      });
    case "voice2":
      return Object.assign({}, state, {
        voice2: action.data.value
      });
    case "userName":
      return Object.assign({}, state, {
        userName: action.data.value
      });
    case "userItem":
      return Object.assign({}, state, {
        userItem: action.data.value
      });
    case "status":
      return Object.assign({}, state, {
        status: action.data.value
      });
    case "token":
      return Object.assign({}, state, {
        token: action.data.value
      });
    case "course":
      return Object.assign({}, state, {
        course: action.data.value
      });
    case "clockNameList":
      return Object.assign({}, state, {
        clockNameList: action.data.value
      });
    case "screenshot":
      return Object.assign({}, state, {
        screenshot: action.data.value
      });
    case "anim":
      return Object.assign({}, state, {
        anim: action.data.value
      });
    case "mute":
      return Object.assign({}, state, {
        mute: action.data.value
      });
    case "agentStudentList" /* 校区内学员信息 */:
      return Object.assign({}, state, {
        agentStudentList: action.data.value
      });
    case "isGuaranty" /* 认证 */:
      return Object.assign({}, state, {
        isGuaranty: action.data.value
      });

    default:
      return false;
  }
}

export function showModal(value) {
  return {
    type: "showModal",
    data: { value }
  };
}
export function voice(value) {
  return { type: "voice", data: { value } };
}
export function voice2(value) {
  return { type: "voice2", data: { value } };
}
export function userName(value) {
  return { type: "userName", data: { value } };
}
export function userItem(value) {
  return { type: "userItem", data: { value } };
}
export function status(value) {
  return { type: "status", data: { value } };
}
export function token(value) {
  return { type: "token", data: { value } };
}
export function course(value) {
  return { type: "course", data: { value } };
}
export function clockNameList(value) {
  return { type: "clockNameList", data: { value } };
}
export function screenshot(value) {
  return { type: "screenshot", data: { value } };
}
export function anim(value) {
  return { type: "anim", data: { value } };
}
export function mute(value) {
  return { type: "mute", data: { value } };
}
export function agentStudentList(value) {
  return { type: "agentStudentList", data: { value } };
}
export function isGuaranty(value) {
  return { type: "isGuaranty", data: { value } };
}
